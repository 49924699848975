import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import Home from "./pages/Home";
import Admin from "./pages/Admin";
import LoginRoute from "./pages/Login";
import LogoutRoute from "./pages/Logout";
import DiscordRollback from "./pages/DiscordRollback";
import NotFound404 from "./pages/NotFound";
import Redirect0 from "./utils/redirect/user_0";

function App() {
  return (
    <Router baseurl="/">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/admin"
          element={
            <>
              <Redirect0 />
              <Admin />
            </>
          }
        />{" "}
        <Route path="/login" element={<LoginRoute />} />
        <Route path="/logout" element={<LogoutRoute />} />
        <Route path="/discordrollback" element={<DiscordRollback />} />
        <Route path="*" element={<NotFound404 className="app-darkmode1" />} />
      </Routes>
    </Router>
  );
}

export default App;
