import React from "react";
import Button from "react-bootstrap/Button";

function Content(props) {
  const classes = `py-5 px-5 text-center ${props.className}`;
  return (
    <div className={classes} style={{ position: "relative" }}>
      <h1>
        <strong>C0NTENT EDIT</strong>
      </h1>
      <Button variant="outline-light">About me</Button>{" "}
      <Button variant="outline-light">My projects</Button>{" "}
      <Button variant="outline-light">Contact me</Button>{" "}
    </div>
  );
}

export default Content;
