import "../styles/Admin.css";
import Dashboard from "../components/Admin/Dashboard";
import Todo from "../components/Admin/Todo";
import Content from "../components/Admin/Content";
import WebStalk from "../components/Admin/WebStalk";

function Admin() {
  return (
    <>
      <Dashboard className="admin-darkmode1" />
      <Todo className="admin-darkmode2" />
      <Content className="admin-darkmode1" />
      <WebStalk className="admin-darkmode2" />
    </>
  );
}

export default Admin;
