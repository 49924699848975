import { useEffect } from "react";

function FollowingDotCursor(options) {
  let hasWrapperEl = options && options.element;
  let element = hasWrapperEl || document.body;

  let width = window.innerWidth;
  let height = window.innerHeight;
  let cursor = { x: width / 2, y: width / 2 };
  let dot = new Dot(width / 2, height / 2, 10, 10);
  let canvas, context, animationFrame;
  let color = options?.color || "#323232a6";

  const prefersReducedMotion = window.matchMedia(
    "(prefers-reduced-motion: reduce)"
  );

  prefersReducedMotion.onchange = () => {
    if (prefersReducedMotion.matches) {
      destroy();
    } else {
      init();
    }
  };

  function init() {
    if (prefersReducedMotion.matches) {
      console.log(
        "This browser has prefers reduced motion turned on, so the cursor did not init"
      );
      return false;
    }

    // Hide the original cursor
    element.style.cursor = "none";

    canvas = document.createElement("canvas");
    context = canvas.getContext("2d");
    canvas.style.cssText =
      "position: fixed; top: 0px; left: 0px; pointer-events: none;";

    document.body.appendChild(canvas);
    canvas.width = width;
    canvas.height = height;

    bindEvents();
    loop();
  }

  function bindEvents() {
    element.addEventListener("mousemove", onMouseMove);
    window.addEventListener("resize", onWindowResize);
  }

  function onWindowResize() {
    width = window.innerWidth;
    height = window.innerHeight;
    canvas.width = width;
    canvas.height = height;
  }

  function onMouseMove(e) {
    cursor.x = e.clientX;
    cursor.y = e.clientY;
  }

  function updateDot() {
    context.clearRect(0, 0, width, height);
    dot.moveTowards(cursor.x, cursor.y, context);
  }

  function loop() {
    updateDot();
    animationFrame = requestAnimationFrame(loop);
  }

  function destroy() {
    element.style.cursor = "";

    canvas.remove();
    cancelAnimationFrame(animationFrame);
    element.removeEventListener("mousemove", onMouseMove);
    window.removeEventListener("resize", onWindowResize);
  }

  function Dot(x, y, width, lag) {
    this.position = { x: x, y: y };
    this.width = width;
    this.lag = lag;

    this.moveTowards = function (x, y, context) {
      this.position.x += (x - this.position.x) / this.lag;
      this.position.y += (y - this.position.y) / this.lag;

      context.fillStyle = color;
      context.beginPath();
      context.arc(this.position.x, this.position.y, this.width, 0, 2 * Math.PI);
      context.fill();
      context.closePath();
    };
  }

  init();

  return {
    destroy: destroy,
  };
}
const Cursor = () => {
  useEffect(() => {
    FollowingDotCursor({
      /* možné nastavení */
    });

    return () => {
      FollowingDotCursor().destroy();
    };
  }, []);

  return null; // Komponenta nevykresluje žádný JSX
};

export default Cursor;
