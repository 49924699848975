import { useEffect } from "react";
import { destroyCookie } from "../utils/Cookies";

const LogoutRoute = () => {
  useEffect(() => {
    destroyCookie("discord_name");
    destroyCookie("user_id");
    destroyCookie("access_token");
    destroyCookie("lvl");
    window.location.href = "/";
  }, []);

  return null;
};

export default LogoutRoute;
