import { useEffect } from "react";

const LoginRoute = () => {
  useEffect(() => {
    const isDev = process.env.REACT_APP_DEV === "1";
    const redirectUri = isDev
      ? process.env.REACT_APP_DEV_REDIRECT
      : process.env.REACT_APP_PRODUCTION_REDIRECT;

    console.log(redirectUri);
    window.location.href = redirectUri;
  }, []);

  return null;
};

export default LoginRoute;
