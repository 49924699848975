import React from "react";
import Table from "react-bootstrap/Table";

function Dashboard(props) {
  const classes = `py-5 px-5 text-center ${props.className}`;
  return (
    <div className={classes} style={{ position: "relative" }}>
      <h1>
        <strong>DASHB0ARD</strong>
      </h1>
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>STATUS</th>
            <th>Switch</th>
            <th>Logs</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>DC BOT</td>
            <td>CWT</td>
            <td>🟢</td>
            <td>TODO</td>
            <td>button</td>
          </tr>
          <tr>
            <td>Py_Cron</td>
            <td>FC_Time</td>
            <td>🟢</td>
            <td>TODO</td>
            <td>button</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default Dashboard;
