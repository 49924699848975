import React from "react";
import "../../styles/Intro.css";
import PhotoOfMe from "../../images/profile.png";

function Intro(props) {
  const classes = `d-flex justify-content-center align-items-center ${props.className}`;
  return (
    <div className={classes} style={{ height: "100vh", position: "relative" }}>
      <img src={PhotoOfMe} className="photoOfMe" alt="TRNASS" />
      <h1 className="overlay-text">
        <strong>TRNASS</strong>
      </h1>
    </div>
  );
}

export default Intro;
