import React, { useState, useEffect } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";

function Contact(props) {
  const [content, setContent] = useState({ heading: "", text: "" });

  const isDev = process.env.REACT_APP_DEV === "1";
  const api_url = isDev
    ? process.env.REACT_APP_DEV_API
    : process.env.REACT_APP_PRODUCTIN_API;

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await axios.get(`${api_url}/content/public/`, {
          params: { component_id: "3" },
          headers: { "Content-Type": "application/json" },
        });
        setContent({
          heading: response.data.heading,
          content: response.data.content,
        });
      } catch (error) {
        console.error("Chyba při načítání obsahu:", error);
      }
    };

    fetchContent();
  }, []);

  const classes = `d-flex text-center justify-content-center align-items-center ${props.className}`;
  return (
    <>
      <div className={classes} style={{ height: "45vh" }}>
        <Container>
          <Row>
            <Col sm={12} md={6}>
              <h1>{content.heading}</h1>
            </Col>
            <Col sm={12} md={6}>
              <p>
                <strong>{content.content}</strong>
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Contact;
