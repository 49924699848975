import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading"; // Ujistěte se, že máte komponentu Loading
import axios from "axios";
import { getCookie } from "../Cookies"; // Předpokládám, že máte tuto funkci

const isDev = process.env.REACT_APP_DEV === "1";
const api_url = isDev
  ? process.env.REACT_APP_DEV_API
  : process.env.REACT_APP_PRODUCTION_API;

const Redirect0 = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserLevel = async () => {
      const discord_id = getCookie("discord_id");

      try {
        const response = await axios.get(`${api_url}/verify/user_lvl/`, {
          params: { discord_id },
          headers: { "Content-Type": "application/json" },
        });

        if (response.data.lvl <= 0) {
          navigate("/");
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Chyba při získávání úrovně uživatele:", error);
        navigate("/"); // V případě chyby přesměrujte uživatele
      }
    };

    fetchUserLevel();
  }, [navigate]);

  if (isLoading) {
    return <Loading />;
  }

  return null; // Nic nezobrazujte, pokud načítání skončilo a uživatel je oprávněn zůstat
};

export default Redirect0;
