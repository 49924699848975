import React, { useEffect, useRef } from "react";
import "../styles/NotFound.css";
import "../styles/Intro.css";
import Cursor from "../utils/Cursor";

const NotFound404 = (props) => {
  const classes = `d-flex justify-content-center align-items-center ${props.className}`;

  const headingRef = useRef(null);
  const backRef = useRef(null);

  const shiftLetters = (element, duration = 50) => {
    const letters = "!@#$%|&*?+/";
    let iteration = 0;
    let interval = setInterval(() => {
      element.innerText = element.innerText
        .split("")
        .map((letter, index) => {
          if (index < iteration) {
            return element.dataset.value[index];
          }
          return letters[Math.floor(Math.random() * letters.length)];
        })
        .join("");

      if (iteration >= element.dataset.value.length) {
        clearInterval(interval);
      }

      iteration += 1 / 3;
    }, duration);
  };

  useEffect(() => {
    if (headingRef.current) {
      shiftLetters(headingRef.current);
      headingRef.current.onmouseover = () => shiftLetters(headingRef.current);
    }

    if (backRef.current) {
      backRef.current.onmouseover = () => shiftLetters(backRef.current, 20);
    }
  }, []);

  return (
    <>
      <Cursor />
      <div className={classes}>
        <section>
          <div className="text">
            <h1 className="h1anim" ref={headingRef} data-value="404">
              404
            </h1>
            <a href="/" ref={backRef} data-value="Zpět">
              Zpět
            </a>
          </div>
        </section>
      </div>
    </>
  );
};

export default NotFound404;
