import React from "react";
import Table from "react-bootstrap/Table";

function WebStalk(props) {
  const classes = `py-5 px-5 text-center ${props.className}`;
  return (
    <div className={classes} style={{ position: "relative" }}>
      <h1>
        <strong>WEB CHECK</strong>
      </h1>
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>Name</th>
            <th>IP</th>
            <th>Ping</th>
            <th>DNS</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>LeoSight.cz</td>
            <td>0.0.0.0</td>
            <td>15ms</td>
            <td>Button</td>
            <td>RELOAD</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default WebStalk;
