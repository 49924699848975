import React, { useEffect, useState } from "react";
import axios from "axios";
import { setCookie, getCookie } from "../utils/Cookies";

const DiscordRollback = () => {
  const isDev = process.env.REACT_APP_DEV === "1";
  const client_id = process.env.REACT_APP_CLIENT_ID;
  const client_secret = process.env.REACT_APP_CLIENT_SECRET;
  let redirect_uri = isDev
    ? process.env.REACT_APP_DEV_REDIRECT_URI
    : process.env.REACT_APP_PRODUCTION_REDIRECT_URI;
  const api_url = isDev
    ? process.env.REACT_APP_DEV_API
    : process.env.REACT_APP_PRODUCTION_API;

  console.log("redirect uri", redirect_uri);
  console.log("isDev", isDev);
  console.log("api_url", api_url);

  const [accessToken, setAccessToken] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    const postDataToken = async (code) => {
      try {
        let response = await axios.post(
          `${api_url}/discord/access_token/`,
          { client_id, client_secret, redirect_uri, code },
          { headers: { "Content-Type": "application/json" } }
        );
        console.log("token:", response.data.access_token);
        setAccessToken(response.data.access_token);
        setCookie("access_token", response.data.access_token, 7);
      } catch (error) {
        console.error("Chyba při odesílání požadavku:", error);
        setError("Error fetching access token");
        if (error.response) {
          console.error("Odpověď serveru:", error.response);
        }
      }
    };

    const getDataUserLvl = async () => {
      let discord_id = getCookie("discord_id");
      try {
        let response = await axios.get(`${api_url}/verify/user_lvl/`, {
          params: { discord_id: discord_id },
          headers: { "Content-Type": "application/json" },
        });
        setCookie("lvl", response.data.lvl, 7);
      } catch (error) {
        console.error("Chyba při odesílání požadavku:", error);
        setError("Error fetching lvl");
        if (error.response) {
          console.error("Odpověď serveru:", error.response);
        }
      }
    };

    const postDataInfo = async () => {
      let token = getCookie("access_token");
      try {
        let response = await axios.post(
          `${api_url}/discord/user_info/`,
          { token },
          { headers: { "Content-Type": "application/json" } }
        );
        console.log("discord_name:", response.data.discord_name);
        console.log("discord_id:", response.data.discord_id);
        setCookie("discord_name", response.data.discord_name, 7);
        setCookie("discord_id", response.data.discord_id, 7);
      } catch (error) {
        console.error("Chyba při odesílání požadavku:", error);
        setError("Error fetching user info");
        if (error.response) {
          console.error("Odpověď serveru:", error.response);
        }
      }
    };

    const fetchAccessToken = async () => {
      const UriParams = new URLSearchParams(window.location.search);
      const code = UriParams.get("code");
      console.log("code:", code);

      if (!code) {
        setError("No code provided for Discord authentication");
        return;
      }

      await postDataToken(code);
      await postDataInfo();
      await getDataUserLvl();
    };

    fetchAccessToken();
  }, []);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!accessToken && !getCookie("access_token")) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div>Token Cookie: {getCookie("access_token")}</div>
      <div>Name Cookie: {getCookie("discord_name")}</div>
      <div>ID Cookie: {getCookie("discord_id")}</div>
      <div>Lvl: {getCookie("lvl")}</div>
    </>
  );
};

export default DiscordRollback;
