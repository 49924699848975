import React from "react";
import Table from "react-bootstrap/Table";

function Todo(props) {
  const classes = `py-5 px-5 text-center ${props.className}`;
  return (
    <div className={classes} style={{ position: "relative" }}>
      <h1>
        <strong>TODO</strong>
      </h1>
      <Table striped bordered hover variant="dark">
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>STATUS</th>
            <th>Notes</th>
            <th>Logs</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1.</td>
            <td>Db</td>
            <td>🟠</td>
            <td>modal (TODO)</td>
            <td>button</td>
          </tr>
          <tr>
            <td>2.</td>
            <td>FC_Time</td>
            <td>🟠</td>
            <td>modal (TODO)</td>
            <td>button</td>
          </tr>
          <tr>
            <td>3.</td>
            <td>User práva</td>
            <td>🟠</td>
            <td>modal (TODO)</td>
            <td>button</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
}

export default Todo;
