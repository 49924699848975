import "../Home.css";
import Intro from "../components/Home/Intro";
import AboutMe from "../components/Home/AboutMe";
import Projects from "../components/Home/Projects";
import Contact from "../components/Home/Contact";
import Cursor from "../utils/Cursor";

function Home() {
  return (
    <>
      <Cursor />
      <Intro className="app-darkmode1" />
      <AboutMe className="app-darkmode2" />
      <Projects className="app-darkmode1" />
      <Contact className="app-darkmode2" />
    </>
  );
}

export default Home;
